// import React from "react";
import React from "react";

// importing images
import landing from "../assets/images/landing.png";
import mainLogo from "../assets/images/trisara main.png";

import { Link } from "react-router-dom";

// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// for db.json
import useFetch from "../utils/useFetch";
import { API_URL } from "../constants";
import { useEffect } from "react";
import NotFound from "./general/notFound";

import "../styles/newHome.css";
import Loader from "../components/general/loader";

const Home = () => {
  // fetching data
  const { data: events, error: events_error } = useFetch(
    `${API_URL}events/upcoming`
  );
  const { data: franchises, error: franchise_error } = useFetch(
    `${API_URL}franchises`
  );

  // for pop up
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (events !== null && events.length > 0) {
      MySwal.fire({
        html: (
          <>
            {events ? (
              <>
                {events.map((event) => (
                  <div key={event.id}>
                    {event.media_type === "image" ? (
                      <img
                        className="pt-5"
                        src={event.media}
                        alt={`${event.name} on ${event.date}`}
                      />
                    ) : event.media_type === "video" ? (
                      <video
                        src={event.media}
                        alt={`${event.name} on ${event.date}`}
                        muted
                        autoPlay={true}
                        loop
                      />
                    ) : (
                      <p>Media not supported.</p>
                    )}

                    {/* for video */}
                  </div>
                ))}
              </>
            ) : events_error ? (
              <p className="pt-32 text-center">{events_error}</p>
            ) : (
              <p className="pt-32 text-center">
                <Loader />
              </p>
            )}
          </>
        ),
        showConfirmButton: false,
        showCloseButton: true,
        width: 1000,
      });
    }
  }, [events, events_error, MySwal]);

  return (
    <div className="h-screen snap-y snap-mandatory overflow-y-scroll" dir="ltr">
      {franchises ? (
        <>
          <div className="h-screen snap-start">
            <section className="landing">
              <img src={landing} alt="landing" className="bg-img" />
              <div className="content">
                <div className="content-wrapper">
                  <img
                    src={mainLogo}
                    alt="trisara logo"
                    className="mx-auto w-[80vw] sm:w-[50vw] lg:w-[30vw]"
                  />
                  <h3 className="logo-text home-white-text text-[20px] xl:text-[27px]">
                    The Third Garden of Heaven
                  </h3>
                  <p className="home-white-text mx-auto px-3 pt-2 sm:p-8 md:px-12 lg:w-[90vw] xl:w-[50vw]">
                    <b>Welcome to Trisara.</b>
                    <br />
                    <br />
                    With our commitment to craft, feel the immersive experiences
                    & bespoke service.
                    <br />
                    <br />
                    Trisara, the third garden of heaven, where passion for
                    culinary excellence is matched only by the commitment to
                    exceptional service; with the chic casual dining layout
                    boast a unique design and service style that somehow gel to
                    create memorable experiences for all. From the aroma of
                    freshly baked goods & the scrumptious food, stay track in a
                    good music and with special charming ambience & exceptional
                    service to your ultimate destination for luxury, comfort and
                    exceptional hospitality, Trisara got you covered for an
                    unforgettable stay & everlasting aftertaste.
                  </p>
                  <button>
                    <Link
                      className="home-button-white"
                      to="/trisara/t_lazimpat/menu"
                    >
                      View Menu
                    </Link>
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="grid-container snap-scroll-item">
            {franchises.map((franchise, index) => (
              <>
                <a
                  href={
                    franchise.coming_soon
                      ? "/openingsoon"
                      : franchise.id === "peros" || franchise.id === "jade"
                      ? `/${franchise.id}/${franchise.outlets[0].id}`
                      : franchise.id
                  }
                  className="grid-item group relative overflow-hidden"
                  key={index}
                >
                  <div className="grid-image" key={franchise.id}>
                    <img
                      alt={`${franchise.name} franchise`}
                      src={franchise.image}
                      className="thumbnail-image h-screen w-full cursor-pointer object-cover grayscale transition-transform duration-[10s] ease-in-out group-hover:scale-125 group-hover:grayscale-0"
                    />
                  </div>
                  <div className="titles absolute bottom-0 z-10 flex h-full w-full flex-col items-center justify-center gap-5 bg-black bg-opacity-40 text-center transition-all duration-700 lg:h-40 lg:bg-[#eee] lg:bg-opacity-100 lg:group-hover:h-60">
                    <img alt="logo" width={"220px"} src={franchise.logo} />
                    <p className="mx-8 hidden p-3 text-center text-sm capitalize text-black opacity-0 xl:group-hover:block xl:group-hover:animate-slow-opacity">
                      {franchise.about}
                    </p>
                  </div>
                </a>
              </>
            ))}
          </div>
        </>
      ) : franchise_error ? (
        <NotFound />
      ) : (
        <p className="pt-32 text-center">
          <Loader />
        </p>
      )}
    </div>
  );
};

export default Home;
