import React, { useContext } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { AiOutlineMenu } from "react-icons/ai";

// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// for reservation form
import Reservation from "./popUps/reservation";
import { useState } from "react";

import useFetch from "../utils/useFetch";
import { API_URL } from "../constants";
import Franchise from "./popUps/franchise";

const Nav = () => {
  // for nav background

  const { data: franchises, error: franchise_error } = useFetch(
    `${API_URL}franchises`
  );

  const [navbar, setNavbar] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  // to open full page menu
  const { toggleMenu } = useContext(MenuContext);

  const ReserationPopUp = withReactContent(Swal);

  const showReservationPopup = () => {
    ReserationPopUp.fire({
      title: "Reserve a Table",
      html: <Reservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  // franchise pop up
  const FranchisePopUp = withReactContent(Swal);
  const showFranchisePopup = () => {
    FranchisePopUp.fire({
      // title: "Franchising Initial Inquiry Form",
      title: "Franchising Inquiry",
      html: <Franchise />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <section
      onMouseEnter={() => window.scrollY <= 80 && setNavbar(true)}
      onMouseLeave={() =>
        window.scrollY <= 80 && setNavbar(false) && setDropdown(false)
      }
      className={`nav ${navbar ? "active" : ""} h-fit`}
      // className={navbar ? "nav active" : "nav"}
    >
      <div className="nav-wrapper xl:flex hidden text-center lg:justify-center text-base w-fit xl:w-full my-5 relative">
        <a href="/" onMouseEnter={() => setDropdown(false)}>
          Home
        </a>
        <div className="dropdown">
          <button onMouseEnter={() => setDropdown(true)}>Outlets</button>
        </div>
        <a href="/openingSoon" onMouseEnter={() => setDropdown(false)}>
          Achievement and community footprints
        </a>
        <a
          onClick={() => {
            showFranchisePopup();
          }}
          onMouseEnter={() => setDropdown(false)}
        >
          Franchise
        </a>
        <a href="/careers" onMouseEnter={() => setDropdown(false)}>
          Careers
        </a>
        <button
          className="2xl:absolute right-5 rounded-md bg-black px-11 py-2 font-semibold text-white transition-all duration-500 ease-in-out hover:bg-[#ebf3e8] hover:text-black hover:outline hover:outline-2 hover:outline-black"
          onClick={() => {
            showReservationPopup();
          }}
        >
          Reserve Now
        </button>
        {/* dropdown menu for outlets */}
        <div
          onMouseLeave={() => setDropdown(false)}
          className={`dropdown-content ${
            dropdown ? "visible" : ""
          } absolute w-screen -z-10 bg-[#f7f7f7] xl:flex xl:justify-center md:columns-3 gap-8 px-28 py-10`}
        >
          {franchises &&
            franchises.map((franchise, index) => (
              <>
                {/* remove jade and trisara mart (for now) */}
                {franchise.coming_soon ? (
                  ""
                ) : (
                  <div
                    className={`pb-4 break-inside-avoid mb-4 border-2 border-nav-green`}
                    key={index}
                  >
                    <a
                      href={
                        franchise.coming_soon
                          ? "/openingsoon"
                          : franchise.id === "peros" || franchise.id === "jade"
                          ? `/${franchise.id}/${franchise.outlets[0].id}`
                          : `/${franchise.id}`
                      }
                      key={index}
                      className="mt-4 font-bold"
                    >
                      <img
                        className="h-8 mx-auto"
                        src={franchise.logo}
                        alt={franchise.name}
                      />
                      {/* {franchise.name} */}
                    </a>
                    <div className="flex flex-row flex-wrap mx-5 justify-center">
                      {franchise.outlets.map((outlet, index) => (
                        <a
                          href={
                            outlet.coming_soon
                              ? "/openingsoon"
                              : `/${franchise.id}/${outlet.id}`
                          }
                          key={index}
                        >
                          <div className="">{outlet.name}</div>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
      </div>

      {/* SLIDE MENU open full page menu */}
      <span className="toggleMenu xl:hidden" onClick={toggleMenu}>
        <AiOutlineMenu />
      </span>
    </section>
  );
};

export default Nav;
