import React from "react";

const Gallery = ({ outlet }) => {
  return (
    <div className="gallery text-center">
      <div className="detail w-screen px-2 lg:w-[63vw]">
        <p className="pre-heading">Discover</p>
        <h1>Gallery</h1>
        <p className="gallery-text px-10">{outlet.gallery_description}</p>
      </div>
      {/* {outlet.gallery.map((item) => (
          <img
            key={item.image}
            className={`rounded-3xl sm:h-[300px] w-full ${
              item.long_version ? "lg:col-span-2" : ""
            } ${!item.display_on_mobile ? "sm:block hidden" : ""}`}
            src={item.image}
            alt="Trisara Gallery"
          />
        ))} */}
      {outlet.gallery[7] ? (
        <div className="grid grid-cols-2 gap-2 px-2 sm:px-10 md:px-20 lg:grid-cols-5 lg:px-40">
          <img
            key={outlet.gallery[0].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !outlet.gallery[0].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={outlet.gallery[0].image}
            alt="Trisara Gallery"
          />
          <img
            key={outlet.gallery[1].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !outlet.gallery[1].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={outlet.gallery[1].image}
            alt="Trisara Gallery"
          />
          <img
            key={outlet.gallery[2].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !outlet.gallery[2].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={outlet.gallery[2].image}
            alt="Trisara Gallery"
          />
          <img
            key={outlet.gallery[3].id}
            className={`w-full rounded-3xl sm:h-[300px] lg:col-span-2 ${
              !outlet.gallery[3].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={outlet.gallery[3].image}
            alt="Trisara Gallery"
          />
          <img
            key={outlet.gallery[4].id}
            className={`w-full rounded-3xl sm:h-[300px] lg:col-span-2 ${
              !outlet.gallery[4].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={outlet.gallery[4].image}
            alt="Trisara Gallery"
          />
          <img
            key={outlet.gallery[5].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !outlet.gallery[5].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={outlet.gallery[5].image}
            alt="Trisara Gallery"
          />
          <img
            key={outlet.gallery[6].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !outlet.gallery[6].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={outlet.gallery[6].image}
            alt="Trisara Gallery"
          />
          <img
            key={outlet.gallery[7].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !outlet.gallery[7].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={outlet.gallery[7].image}
            alt="Trisara Gallery"
          />
        </div>
      ) : (
        <p>Images Unavailable.</p>
      )}
    </div>
  );
};

export default Gallery;
