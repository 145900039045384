import React from "react";

// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// for vacancyApplication form
import JadeReservation from "../../components/popUps/jadeReservation";

const Playground = () => {
  const ReserationPopUp = withReactContent(Swal);
  const showJadeReservationPopup = () => {
    ReserationPopUp.fire({
      // title: "Jade Reservation Form",
      // title: "Book a Room",
      // imageUrl:
      //   "https://media.trisaranepal.com/outlet_logos/trisara_jade-01.png",
      // imageWidth: 200,
      // imageAlt: "Custom image",
      html: <JadeReservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };
  return (
    <div
      className="mt-96"
      onClick={() => {
        showJadeReservationPopup();
      }}
    >
      Playground
    </div>
  );
};

export default Playground;
