import React, { useState } from "react";
import { ToastNotification } from "../../utils/ToastNotification";
import axios from "axios";
import { API_URL } from "../../constants";

const Franchise = () => {
	const [fullName, setFullName] = useState("");
	const [age, setAge] = useState(0);
	const [address, setAddress] = useState("");
	const [contactNumber, setContactNumber] = useState("");
	const [email, setEmail] = useState("");
	const [invest, setInvest] = useState("");
	const [areas, setAreas] = useState("");
	const [date, setDate] = useState("");
	const [business, setBusiness] = useState(false);
	const [details, setDetails] = useState("");

	const submitFranchise = (event) => {
		event.preventDefault();
		let data = JSON.stringify({
			full_name: fullName,
			email: email,
			contact: address,
			address: address,
			age: age,
			investment: invest,
			areas_of_interest: areas,
			experience: details,
      franchise_date_start: date,
		});
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_URL}apply_franchise/`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Basic ${window.btoa(
					"frontend:WWEQgN9.%AAw]GfR"
				)}`,
			},
			data: data,
		};
		axios
			.request(config)
			.then((response) => {
				ToastNotification.fire({
					icon: "success",
					// timer: 30000,
					title: "Application for Franchise Successful!",
				});
			})
			.catch((error) => {
				console.log(error);
				ToastNotification.fire({
					icon: "warning",
					// timer: 3000000,
					title: "Application for Franchise Failed!",
				});
			});
	};
	return (
		<form className="md:px-8 px-3 pt-2" onSubmit={submitFranchise}>
			{/* text */}
			<p className="text-xs text-center text-neutral-400 pb-6">
				Thank you for your interest in becoming a Trisara's Franchisee.
				<br />
				Please fill out the following form and we will be in touch
				regarding next steps.
			</p>
			{/* Full Name */}
			<div className="group relative z-0 mb-6 w-full">
				<input
					type="text"
					name="first_name"
					id="first_name"
					className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
					value={fullName}
					onChange={(e) => {
						setFullName(e.target.value);
					}}
					placeholder=" "
					required
				/>
				<label
					htmlFor="first_name"
					className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0  peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
				>
					Full Name <span className="text-rose-500">*</span>
				</label>
			</div>
			{/* Email */}
			<div className="group relative z-0 mb-6 w-full">
				<input
					type="email"
					name="email"
					id="email"
					className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
					value={email}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					placeholder=" "
					required
				/>
				<label
					htmlFor="email"
					className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0  peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
				>
					Email <span className="text-rose-500">*</span>
				</label>
			</div>
			{/* Contact Number | Address */}
			<div className="mt-6 grid md:grid-cols-2 md:gap-6">
				{/* Contact Number */}
				<div className="group relative z-0 mb-6 w-full">
					<input
						type="number"
						pattern="[0-9]{10}"
						name="phone"
						id="phone"
						className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
						value={contactNumber}
						onChange={(e) => setContactNumber(e.target.value)}
						placeholder=" "
						required
					/>
					<label
						htmlFor="phone"
						className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
					>
						Contact Number (98xxxxxxxx){" "}
						<span className="text-rose-500">*</span>
					</label>
				</div>
				{/* Address */}
				<div className="group relative z-0 mb-6 w-full">
					<input
						type="text"
						name="address"
						id="address"
						className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
						value={address}
						onChange={(e) => {
							setAddress(e.target.value);
						}}
						placeholder=" "
						required
					/>
					<label
						htmlFor="address"
						className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0  peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
					>
						Address <span className="text-rose-500">*</span>
					</label>
				</div>
			</div>
			{/* Age | Invest */}
			<div className="grid md:grid-cols-2 md:gap-6">
				{/* Age */}
				<div className="group relative z-0 mb-6 w-full">
					<input
						type="number"
						min={18}
						name="first_name"
						id="first_name"
						className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
						value={age}
						onChange={(e) => {
							setAge(e.target.value);
						}}
						placeholder=" "
						required
					/>
					<label
						htmlFor="first_name"
						className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0  peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
					>
						Age <span className="text-rose-500">*</span>
					</label>
				</div>
				{/* Invest */}
				<div className="group relative z-0 mb-6 w-full">
					<input
						type="number"
						name="invest"
						id="invest"
						className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
						value={invest}
						onChange={(e) => {
							setInvest(e.target.value);
						}}
						placeholder=" "
						required
					/>
					<label
						htmlFor="invest"
						className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0  peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
					>
						Total Cash Available to invest{" "}
						<span className="text-rose-500">*</span>
					</label>
				</div>
			</div>
			{/* Areas */}
			<div className="group relative z-0 mb-6 pt-4 w-full">
				<textarea
					type="text"
					name="areas"
					id="areas"
					className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
					value={areas}
					onChange={(e) => {
						setAreas(e.target.value);
					}}
					placeholder=" "
					required
				/>
				<label
					htmlFor="areas"
					className="absolute text-left top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
				>
					Areas of interest - list the area(s) in which you would like
					to open an outlet.
					<span className="text-rose-500">*</span>
				</label>
			</div>
			{/* Date */}
			<div className="relative mb-6 md:pt-0 pt-4">
				<input
					datepicker="true"
					type="date"
					id="date"
					value={date}
					onChange={(e) => setDate(e.target.value)}
					className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
					required
				/>
				<label
					htmlFor="date"
					className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
				>
					When do you plan to start your franchise?{" "}
					<span className="text-rose-500">*</span>
				</label>
			</div>

			<p className="font-bold mb-5">Business or Franchise information</p>
			{/* Have you ever owned your own business or franchise? */}
			<div className="flex items-center mb-6">
				<input
					id="default-checkbox"
					type="checkbox"
					value={business}
					onChange={(e) => setBusiness(e.target.checked)}
					className="w-4 h-4 bg-gray-100 border-gray-300 rounded"
				/>
				<label
					htmlFor="default-checkbox"
					className="ml-2 text-sm font-medium text-gray-900 cursor-pointer"
				>
					Have you ever owned your own business or franchise?
				</label>
			</div>
			{/* Additional Details */}
			{business && (
				<div className="group relative z-0 mb-6 pt-4 w-full">
					<textarea
						type="text"
						name="areas"
						id="areas"
						className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
						value={details}
						onChange={(e) => {
							setDetails(e.target.value);
						}}
						placeholder=" "
						required
					/>
					<label
						htmlFor="areas"
						className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0  peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
					>
						Please provide additional details about your business or
						franchise.
						<span className="text-rose-500">*</span>
					</label>
				</div>
			)}

			<button
				type="submit"
				className="mx-auto block w-full rounded-lg bg-green px-5 py-2.5 text-center text-sm font-medium text-gray-50 hover:bg-green-700 focus:outline-none sm:w-auto"
			>
				Submit
			</button>
		</form>
	);
};

export default Franchise;
