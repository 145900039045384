import React from "react";
// import Outlets from "./outlets";
import { Link, Outlet } from "react-router-dom";

const Landing = ({ outlet }) => {
  return (
    <>
      <img
        className="bg-outlet"
        src={outlet.background_image}
        alt={`trisara ${outlet.id} background`}
      />
      <div className="px-2 lg:px-20 h-[100vh]">
        <div className="mx-auto table h-screen">
          <div className="table-cell text-center align-middle">
            <img
              className="mx-auto sm:w-[40vw] lg:w-[20vw]"
              src={outlet.logo}
              alt={`trisara ${outlet.id} logo`}
            />
            <h1 className="my-5 text-[20px] font-medium uppercase tracking-[9px] md:text-[1.875vw] md:tracking-[24px]">
              {outlet.name}
            </h1>

            <p className="mx-5 md:mx-12 lg:mx-0 lg:w-[50vw] whitespace-pre-line">
              {outlet.description}
            </p>
            <br />
            <button>
              {outlet.franchise ===
              "https://api.trisaranepal.com/franchises/jade/" ? (
                ""
              ) : (
                <Link to="./menu">View Menu</Link>
              )}
            </button>
          </div>
        </div>
        {/* <Outlets /> */}
        <div className="relative top-[-100px] mx-auto block w-fit">
          <div className="mouse mx-auto hidden lg:block"></div>
          <div className="scroll mx-auto mb-2 block lg:hidden"></div>
          <p className="pt-3 text-xs font-semibold uppercase">Scroll Down</p>
        </div>
      </div>
    </>
  );
};

export default Landing;
