import React from "react";
import "./playground.css";

// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Playground2 = () => {
  const MySwal2 = withReactContent(Swal);
  function a() {
    (async () => {
      const { value: file } = await MySwal2.fire({
        title: "JOBS AND CAREER",
        html: (
          <form className="px-10">
            {/* FName | LName */}
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="group relative z-0 mb-6 w-full">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="first_name"
                  className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500"
                >
                  First name
                </label>
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="last_name"
                  className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500"
                >
                  Last name
                </label>
              </div>
            </div>

            {/* Email */}
            <div className="group relative z-0 mb-6 w-full">
              <input
                type="email"
                name="email"
                id="email"
                className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
                placeholder=" "
                required
              />
              <label
                htmlFor="email"
                className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500"
              >
                Email address
              </label>
            </div>

            {/* Contact | Address */}
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="group relative z-0 mb-6 w-full">
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  name="phone"
                  id="phone"
                  className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="phone"
                  className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500"
                >
                  Contact Number (9812345678)
                </label>
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="address"
                  className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500"
                >
                  Address
                </label>
              </div>
            </div>

            {/* Apply */}
            <div className="group relative z-0 mb-6 w-full">
              <label htmlFor="applied" className="sr-only">
                Apply
              </label>
              <select
                id="applied"
                name="applied"
                className="peer block w-full appearance-none border-0 border-b-2 border-gray-200 bg-transparent px-0 py-2.5 text-sm text-gray-500 focus:border-gray-200 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-400"
                required
              >
                <option value="" defaultValue>
                  Select an option
                </option>
                <option value="Vacancies">Vacancies</option>
                <option value="Internship">Internship</option>
                <option value="Training">Training</option>
                <option value="Workshop">Workshop</option>
              </select>
            </div>
          </form>
        ),
        input: "file",
        inputAttributes: {
          accept: ".pdf,.jpg",
          "aria-label": "Upload your profile picture",
        },
        inputValidator: (value) => {
          return new Promise((resolve) => {
            // empty
            if (!value) {
              resolve("Please choose a file");
              resolve(console.log(value));
            }

            // extension
            if (
              value.type === "image/jpeg" ||
              value.type === "application/pdf"
            ) {
              resolve();
            } else {
              resolve("Extension type not allowed");
            }

            // max file size
            const maxFileSize = 520; // 5MB
            const fileSizeKiloBytes = value.size / 1024;

            if (fileSizeKiloBytes > maxFileSize) {
              resolve("File size is greater than maximum limit");
            }
          });
        },
      });

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          MySwal2.fire({
            title: "Your uploaded picture",
            imageUrl: e.target.result,
            imageAlt: "The uploaded picture",
          });
        };
        reader.readAsDataURL(file);
      }
    })();
  }
  return (
    <div className="mt-96">
      <button onClick={a}>click here</button>
    </div>
  );
};

export default Playground2;
